import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "layout": "post",
  "title": "Why Hiring Veterans Can Reduce Talent Acquisition and Training Costs",
  "author": "Anthony",
  "date": "2018-02-09T06:26:52.000Z",
  "categories": ["Veteran Hiring"],
  "slug": "why-hiring-veterans-can-reduce-talent-acquisition-and-training-costs",
  "draft": false,
  "meta_title": "Why Hiring Veterans Can Reduce Talent Acquisition and Training Costs"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Hiring the best candidate for the job typically takes a lot of time and money. While you feel pressure from leadership to fill your open positions as quickly as possible, recruiting the most qualified candidates will save you more time, money, and frustration in the long run.`}</p>
    <p>{`Scrambling to just quickly fill your open jobs can result in loss of money, time invested and increased attrition rates by not having the right talent in the role.`}</p>
    <p>{`So, how can you cost-effectively hire and retain top-notch employees? A good starting point is tapping into a pool of the most skilled, reliable, and impressive candidates. Here’s why the most qualified person for the job could very well be a `}<strong parentName="p">{`veteran`}</strong>{`:`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Mission Focus`}</strong></li>
    </ol>
    <p>{`A military lifestyle by nature is mission-focused. Veterans thrive in a culture built on cooperation, personal development, and overcoming obstacles to get the job done. These values naturally translate into civilian roles.`}</p>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol"><strong parentName="li">{`Broad Spectrum Leaders`}</strong></li>
    </ol>
    <p>{`Veterans possess a wide range of solid leadership experience. Many soldiers become non-commissioned officers who are placed in leadership positions by the age of 20. Throughout military careers, these leaders are taught responsibility, integrity, and decision-making techniques that develop into strong, natural leadership qualities.`}</p>
    <p>{`Intuition is a skill most veterans possess when they exit the military that can enhance civilian job requirements like problem-solving, strategizing, and decision making. Because military experience inevitably strengthens intuition, veterans are well fit for leadership and team-building roles.   `}</p>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol"><strong parentName="li">{`Shortened Onboarding Process`}</strong></li>
    </ol>
    <p>{`Often times, candidates are brought into positions with limited training and need to be handheld as they onboard. Veterans’ history of intensive training and formative real-world experiences allow them to confidently lead from day one. Because strategic leadership skills have been acquired from years of military experience in rigorous training programs, vets may require less training, saving your company time and money during the onboarding process.`}</p>
    <p>{`Veterans are extremely qualified for a number of civilian roles, but in order to get a clear picture of `}<a parentName="p" {...{
        "href": "https://mailchi.mp/purepost.co/purepostco-recruiter-early-access-registration"
      }}>{`how their skills match your job descriptions`}</a>{`, you need to first understand how their experience can best support your company — which is easier said than done in most HR departments today. You need a solution to translate military skills from resumes in ways you’ll _actually be able to understand._If you’d like to learn more about a veteran career platform that will provide you with veteran resumes that fit the roles you’re trying to fill as quickly and effectively as possible, request a call with a member of our team!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      